'use strict'

###*
 # @ngdoc service
 # @name ReportTemplateManager.factory:ReportTemplateManager

 # @description

###
angular
  .module 'mundoReporting'
  .factory 'ReportTemplateManager', [
    '$log'
    '$q'
    'Restangular'
    'ReportConfigurationManager'
    '$translate'
    'ReportSourceManager'
    '_'
    (
      $log
      $q
      Restangular
      ReportConfigurationManager
      $translate
      ReportSourceManager
      _
    ) ->

      ReportTemplateManagerBase = {}

      ## Create a new template, based on a configuration
      ## This basically adds a "template: true" flag to the configuration
      ReportTemplateManagerBase.create = (configuration) ->
        return Restangular.all 'services/reports/templates'
          .customPOST
            configuration: configuration._id

      ReportTemplateManagerBase.instantiate = (template) ->
        return Restangular.all 'services/reports/templates/instantiate'
          .customPOST
            template: template._id

      ReportTemplateManagerBase.remove = (template) ->
        return ReportConfigurationManager.one template._id
          .remove()

      ReportTemplateManagerBase.override = (template, configuration) ->
        return Restangular.all 'services/reports/templates/override'
          .customPost
            template: template._id
            configuration: configuration._id

      ReportTemplateManagerBase.getSettingsForm = (configuration) ->
        form = [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'Title'
            placeholder: 'Title'
            required: true
            focus: true
        ]
        form

      ReportTemplateManagerBase

  ]
